import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0010 from '../../../components/molecules/columnDetailInner0010'
import UsefulFunctionLayout008 from '../../../components/molecules/usefulFunctionLayout008'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0010 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '裁量労働制とはどんな制度？内容やメリット・デメリットを解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="裁量労働制とはどんな制度？内容やメリット・デメリットを解説"
        description="裁量労働制は労使間で取り決めた時間を労働したと「みなして」賃金を支払う制度「みなし労働制度」の一つです。適正な運用の他、継続して続けられる制度か検証が必要です。"
        ogUrl="https://kintaicloud.jp/column/details/C0010/"
        ogType="article"
        ogTitle="裁量労働制とはどんな制度？内容やメリット・デメリットを解説"
        ogDescription="裁量労働制は労使間で取り決めた時間を労働したと「みなして」賃金を支払う制度「みなし労働制度」の一つです。適正な運用の他、継続して続けられる制度か検証が必要です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0010.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0010 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout008 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0010

export const pageQuery = graphql`
  query C0010 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
