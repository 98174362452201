import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0010 = () => (
  <Wrapper>
    <ColumnH1 label="裁量労働制とはどんな制度？内容やメリット・デメリットを解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        一般的な固定労働時間制とは異なる制度として<span>「裁量労働制」</span>
        があります。新入社員からベテラン社員まで画一的に導入することは困難ですが、一定の要件を満たした社員に限り導入するという選択も取られています。
        <br />
        導入に当たっては対象となる業種に制限があることや手続きや定期報告など、通常の事務手続きとは異なる部分も多く存在する制度です。
        <br />
        <br />
        今回は裁量労働制にフォーカスをあて解説してまいります。
      </p>
      <img src="/images/column/details/c0010.jpg" alt="キンクラコラム画像10" />
      <h1>裁量労働制の種類</h1>
      <p>
        裁量労働制の種類は労働基準法に2種類の定めがあります。詳細は後述しますが、裁量労働制には
        <span>専門業務型裁量労働制</span>と<span>企画業務型裁量労働制</span>
        があります。それぞれに特徴と注意点がありますが、共通する部分として裁量労働制は実際の労働時間に対して賃金が支払われるということではなく、労使間で取り決めた時間を労働したと「みなして」賃金を支払うということです。
        <br />
        例えば月曜日から金曜日は8時間と定めた場合に、月曜日に3時間しか労働しなかったとしても賃金の減額はありません。また、火曜日に10時間働いたとしても残業代は発生しないということです。
        <br />
        <br />
        労務管理上、本来必要である欠勤控除や残業代の支払いなどに要する
        <span>人的労力を節約できる</span>
        と考えます。また、労働者目線でも通常の労務管理とは異なり具体的な指揮監督が及ばないことから、
        <span>自身の能力を発揮しやすい</span>といった面もあります。
      </p>
      <h1>専門業務型裁量労働制について</h1>
      <p>
        適用可能な業種というものが<span>厚生労働省令</span>
        で定められており、具体的には放送番組のプロデューサーなど、会社からの具体的な指揮監督になじまないような一種の独創性が必要である業種であることが要件です。よって、そもそも対象となっていない業種には専門業務型裁量労働制を採用することはできないということです。
        <br />
        導入前には必ず対象となる業種であるか否かを確認すべきです。
        <br />
        <br />
        また、専門業務型裁量労働制の適用にあたっては、適用業種である事に加え、
        <span>「実質的に」裁量権のある労務の提供を行っているか</span>
        という点も注意が必要です。例として、情報処理システムの分析または設計の業務自体は適用業種ですが、管理者の指示を受け、労働するアシスタント的な立場の労働者は、裁量労働制の適用外となります。
        <br />
        <br />
        尚、専門業務型裁量労働制の採用にあたっては、労使協定の締結は必要であるものの、必ずしも労働者の同意は必要とされていません。
      </p>
      <div className="link-div">
        参考
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://www.mhlw.go.jp/general/seido/roudou/senmon/"
            target="_blank"
            rel="noreferrer"
          >
            厚生労働省（専門業務型裁量労働制 適用業種）
          </Link>
        </p>
      </div>
      <Introduction001 />
      <h1>企画業務型裁量労働制について</h1>
      <p>
        事業活動の中枢を担う労働者が創造的な能力を発揮しやすいように設けられた働き方と定義されています。しかし、導入に当たっては、専門業務型裁量労働制よりも要件が厳しことが特徴で
        <span>労使委員会の5分の4以上の議決が必要</span>
        であり、労働者の労働時間の状況や健康および福祉を確保するための措置の実施状況について
        <span>所轄労働基準監督署へ定期報告が義務</span>付けられています。
        <br />
        そして、対象となる業種は事業運営の企画、立案、調査および分析に関する業種とされており、業務の性質上、業務の遂行手段や時間配分の決定を労働者の裁量に委ねる必要があり、使用者が具体的な指示をしない業務とされています。
        <br />
        <br />
        尚、専門業務型裁量労働制と異なり、企画業務型裁量労働制は
        <span>労働者の同意</span>が必要となります。
      </p>
      <h1>裁量労働制と残業代の関係性について</h1>
      <p>
        裁量労働制は残業代が発生しないという考え方は適切ではなく、以下のケースは発生します。働いたとみなす時間が1日8時間超と取り決める場合、深夜労働・法定休日労働をさせた場合は割増賃金を支払う必要があります。
        <br />
        裁量労働制はあくまで<span>労働時間のみなし制度</span>
        であり、深夜や休日労働も含めてみなすということではありません。当然、休憩に関する規定も適用されます。
        <br />
        <br />
        尚、深夜労働とは22時から翌朝5時までの特定の時間帯を指します。また、通常の固定労働時間制よりも流動的な運用が可能である裁量労働制といえども、適切な実労働時間の管理・記録は必須となりますので、その点でも準備が必要です。
        <br />
        <br />
        尚、残業代の時効は
        <span>2020年4月1日以後5年（当分の間は3年）に延長</span>
        されており、将来的に発覚した場合は経営問題にまで発展することから慎重に進めたい部分です。
      </p>
      <h1 className="read">最後に</h1>
      <p>
        裁量労働制の本来の目的は専門的な能力等を有する労働者に可能な限り能力を発揮してもらう制度と言えます。しかし、残業代を払わなくても良いという誤った考え方が独り歩きしているケースも散見されます。
        <br />
        裁量労働制であっても残業代が発生するケースがあるということ、専門業務型裁量労働制の適用業種、企画業務型裁量労働制の手続きについては、自社で運用が難しい場合は外部機関や専門家の意見も交えながら、適正に運用していくことが肝要です。
        <br />
        <br />
        また、法的には適正に運用できていたとしても常態的な深夜労働の事実が確認できる場合などは労働者の健康状態が定期健康診断だけでは把握できないことも想定されます。
        <br />
        定期的に面談を実施するなど、
        <span>
          制度の適法性のみにフォーカスするのではなく、並行して継続して続けられる制度であるか否かの検証
        </span>
        も進めていくべきです。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0010
